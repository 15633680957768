.ag-accordion {
  & ion-accordion.accordion-collapsed ion-item[slot="header"] {
    color: var(--ag-greys-black);
  }

  & ion-item.ag-accordion-header[slot="header"] {
    color: var(--ag-greys-black);
    font-weight: 600;

    & ion-label {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    & .ion-accordion-toggle-icon {
      color: var(--ag-greys-black);
      font-size: 22px;
    }
  }

  div.ag-accordion-content[slot="content"] {
    font-size: 16px;
    color: var(--ag-text-darkGrey);
    padding-bottom: 10px;
    line-height: 1.4rem;

    // Hacks to deal with badly formatted cms content
    > span {
      display: block;
      margin-bottom: 16px;
    }

    & li,
    & ol {
      margin-bottom: 12px;
    }
  }
}

ion-card.ag-accordion-split-mode {
  margin-left: 0px;
  margin-right: 0px;
  border: 1px var(--ag-greys-light) solid;
  border-radius: 4px;
  box-shadow: none;

  & ion-item.ag-accordion-header[slot="header"] {
    --background: var(--ag-greys-white);
  }

  div.ag-accordion-content[slot="content"] {
    color: var(--ag-text-darkGrey);
    font-size: 16px;
    padding: 0px 16px;
  }

  & ion-accordion::part(content) {
    background: var(--ag-greys-white);
  }
}
