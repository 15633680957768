.ag-desktop-cta {
  margin-bottom: 64px;
}

.ag-desktop-cta,
.ag-desktop-cta > div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
