.ag-nav-footer {
  background-color: var(--ag-greys-white);
  border-top: 1px solid /* copied from ion-tab-bar style */
    var(
      --ion-tab-bar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))
    );
  padding-top: 12px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .ag-nav-footer-items {
    margin: auto;
    padding-left: var(--ion-safe-area-left);
    padding-right: var(--ion-safe-area-right);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .ag-nav-footer-item {
    --ionicon-stroke-width: 48px;
    max-width: 168px; /* magic voodoo number copied from ion-tab-button styling */
    width: 100%;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 0;
    color: var(--ag-greys-darkest);

    .ion-icon {
      margin-bottom: 8px;

      &.settings {
        width: 22px;
        height: 22px;
      }
    }

    &.selected {
      stroke: var(--ag-primary-darkest);
      fill: var(--ag-primary-darkest);

      ion-icon {
        color: var(--ag-primary-darkest);
      }

      ion-label div span {
        font-weight: 700;
        color: var(--ag-primary-darkest);
      }
    }
  }
}

.notifications-unread {
  position: absolute;
  top: 0%;
  right: 36%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--ag-status-danger);
}
