.splash-container {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;

  .hero {
    flex-grow: 1;
    width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("../../../public/assets/annie-spratt-JMjNnQ2xFoY-unsplash.webp");
    background-size: cover;
  }

  .splash-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100%;

    img.hero {
      flex-grow: 1;
      width: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      object-fit: cover;
      max-height: 80vh;
    }

    section {
      flex-shrink: 0;
      width: 100%;
      border-radius: 1em 1em 0 0;
      background-color: #cbedfd;
      padding: 3em 1em 2em;
      text-align: center;
      margin-top: -1em;

      & h1 {
        color: #002664;
        font-size: var(--ag-text-display-large-size);
        line-height: var(--ag-text-display-large-line-height);
        font-weight: var(--ag-text-display-large-weight);
      }

      & img {
        margin: 4.5em auto 0;
        margin-top: 72px;
      }
    }
  }
}
