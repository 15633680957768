/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Public Sans Variable", "Public Sans", sans-serif;
  --ion-background-color: var(--ag-primary-surface);

  --ag-greys-black: #000000;
  --ag-greys-darkest: #22272b;
  --ag-greys-darker: #495054;
  --ag-greys-light: #cdd3d6;
  --ag-greys-lighter: #e8ebec;
  --ag-greys-lightest: #f8f6f5;
  --ag-greys-white: #ffffff;

  --ag-primary-darkest: #941b00;
  --ag-primary-darker: #f3631b;
  --ag-primary-dark: #ffce99;
  --ag-primary-light: #ffe2c8;
  --ag-primary-lightest: #fdeddf;
  --ag-primary-surface: #fff8f1;

  --ag-text-dark: var(--ag-greys-darkest);
  --ag-text-darkGrey: #3e3c3b;
  --ag-text-grey: #6e7071;
  --ag-text-light: var(--ag-greys-white);
  --ag-text-highlight: #0085b3;
  --ag-text-visited: #551a8b;

  --ag-status-info: #2e5299;
  --ag-status-info-background: #eaedf4;
  --ag-status-success: #008a07;
  --ag-status-success-background: #e5f6e6;
  --ag-status-warning: #c95000;
  --ag-status-warning-background: #fbeee5;
  --ag-status-danger: #b81237;
  --ag-status-danger-background: #f7e7eb;

  --ag-zone-emergency: rgba(208, 225, 248, 0.5);
  --ag-zone-emergency-dark: #2d85fc;
  --ag-zone-surveillance: rgba(218, 161, 242, 0.5);
  --ag-zone-surveillance-dark: #c235fe;
  --ag-zone-eradication: rgba(238, 111, 97, 0.5);
  --ag-zone-eradication-dark: #c21906;

  /** primary **/
  --ion-color-primary: var(--ag-primary-darkest);
  --ion-color-primary-rgb: 148, 27, 0;
  --ion-color-primary-contrast: var(--ag-greys-white);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--ag-primary-darkest);
  --ion-color-primary-tint: var(--ag-primary-darkest);

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ag-small-drop-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);

  --ag-map-border-radius: 12px;
}
