.fake-input-wrapper {
  --padding-start: 0;
  --inner-padding-start: 16px;
  --inner-border-width: 0;
  --border-width: 1px;
  --border-color: var(--ag-greys-darkest);
  --border-radius: 4px;

  --background: #fff;

  ion-select {
    &::part(container) {
      width: 100%;
    }

    &::part(icon) {
      /* keep the icon as gray instead of --highlight-color which is currently red */
      --highlight-color: var(--ion-color-step-500, gray);
    }
  }
}
