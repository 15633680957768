.ag-zoom-to-me {
  width: 100%;

  > .leaflet-control {
    width: 100%;

    .zoom-to-me {
      --padding-start: 10px;
      --padding-end: 10px;
      // margin-right: 24px;
      // transform: translate(-100%, 0);
    }
  }
}
