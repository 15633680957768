ion-card.ag-card {
  margin: 0;
  margin-bottom: 1em;
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid;
  --background: var(--ag-greys-white);
  border-color: var(--ag-greys-light);

  & ion-card-header.ag-card-header {
    padding-left: 16px;
    padding-bottom: 0px;
  }

  & ion-card-content {
    padding-left: 16px;

    &.contains-map {
      padding: 0px 14px;
    }
  }

  & ion-card-title.ag-card-title {
    margin: 0px;

    & span {
      color: var(--ag-text-dark);
      font-weight: 600;
    }
  }

  &.ag-primary {
    border-color: var(--ag-primary-darkest);
    --background: var(--ag-primary-lightest);
    border-left-width: 6px;
  }

  &.ag-secondary {
    --background: var(--ag-greys-white);
    border-color: var(--ag-greys-darkest);
    border-left-width: 6px;
  }

  & .ag-alert-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    float: left;
    margin-right: 12px;


    & ion-icon.ag-alert-icon {
      font-size: 15px;

      &.ag-alert-icon-color-success {
        color: var(--ag-status-success-background);
      }

      &.ag-alert-icon-color-info {
        color: var(--ag-status-info-background);
      }

      &.ag-alert-icon-color-warning {
        color: var(--ag-status-warning-background);
      }

      &.ag-alert-icon-color-danger {
        color: var(--ag-status-danger-background);
      }
    }

    &.ag-alert-icon-wrapper-info {
      background-color: var(--ag-status-info);
    }

    &.ag-alert-icon-wrapper-success {
      background-color: var(--ag-status-success);
    }

    &.ag-alert-icon-wrapper-warning {
      background-color: var(--ag-status-warning);
    }

    &.ag-alert-icon-wrapper-danger {
      background-color: var(--ag-status-danger);
    }
  }

  &.ag-info {
    --background: var(--ag-status-info-background);
    border-color: var(--ag-status-info);
    border-left-width: 6px;
  }

  &.ag-success {
    --background: var(--ag-status-success-background);
    border-color: var(--ag-status-success);
    border-left-width: 6px;
  }

  &.ag-warning {
    --background: var(--ag-status-warning-background);
    border-color: var(--ag-status-warning);
    border-left-width: 6px;
  }

  &.ag-danger {
    --background: var(--ag-status-danger-background);
    border-color: var(--ag-status-danger);
    border-left-width: 6px;
  }

  &.custom-border {
    border-left-width: 6px;
  }

  & ion-icon.ag-card-icon {
    font-size: 20px;
    padding-right: 5px;
    color: var(--ag-greys-darkest);
  }

  & ion-icon.ag-card-icon.ag-card-icon-right {
    float: right;
    padding-right: 0px;
  }

  & .ag-card-is-alert-variant {
    & ion-card-subtitle {
      margin-left: 35px;
    }

    & .ag-children-alert-card-content {
      margin-left: 35px;
    }

    & .ag-children-card-alert-actions {
      margin-left: 25px;
    }
  }
}
