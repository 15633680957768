ion-item.ag-show-hide-content {
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  & ion-button {
    text-transform: none;
    margin: 0;
    padding: 0;
    width: 52px;
    --box-shadow: none;
    --background: none;
    --background-hover: var(--ag-primary-dark);
    --ripple-color: var(--ag-primary-light);
    --color: var(--ag-greys-darkest);
    font-size: 14px;

    & span {
      color: var(--ag-primary-darkest);
    }
  }
}
