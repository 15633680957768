:root {
  --nsw-status-info-blue: #2e5299;
  --nsw-status-info-blue-bg: #eaedf4;
  --nsw-status-success-green: #008a07;
  --nsw-status-success-green-bg: #e5f6e6;
  --nsw-status-warning-orange: #c95000;
  --nsw-status-warning-orange-bg: #fbeee5;
  --nsw-status-error-red: #b81237;
  --nsw-status-error-red-bg: #f7e7eb;
}

html,
body {
  background-color: var(--ag-primary-surface);
}

ion-content {
  &.ag-app-padding {
    --padding-top: 32px;
    --padding-bottom: 32px;
    --padding-start: 24px;
    --padding-end: 24px;
  }

  &::part(background) {
    background-color: var(--ag-primary-surface);
  }
}

ion-popover {
  &.no-background::part(backdrop) {
    background-color: rgba(0, 0, 0, 0);
  }
}

ion-grid {
  --ion-grid-column-padding: 4px;
}

.ion-page {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;

  .ag-app-content {
    max-width: 992px;
  }
}

$spacingScale: (
  "auto": auto,
  "0": 0,
  "2": 2px,
  "4": 4px,
  "8": 8px,
  "10": 10px,
  "12": 12px,
  "16": 16px,
  "24": 24px,
  "32": 32px,
);

@each $key, $px in $spacingScale {
  .padding-#{$key} {
    padding: $px;
  }
  .padding-left-#{$key} {
    padding-left: $px;
  }
  .padding-top-#{$key} {
    padding-top: $px;
  }
  .padding-right-#{$key} {
    padding-right: $px;
  }
  .padding-bottom-#{$key} {
    padding-bottom: $px;
  }
  .padding-x-#{$key} {
    padding-left: $px;
    padding-right: $px;
  }
  .padding-y-#{$key} {
    padding-top: $px;
    padding-bottom: $px;
  }
  .margin-#{$key} {
    margin: $px;
  }
  .margin-left-#{$key} {
    margin-left: $px;
  }
  .margin-top-#{$key} {
    margin-top: $px;
  }
  .margin-right-#{$key} {
    margin-right: $px;
  }
  .margin-bottom-#{$key} {
    margin-bottom: $px;
  }
  .margin-x-#{$key} {
    margin-left: $px;
    margin-right: $px;
  }
  .margin-y-#{$key} {
    margin-top: $px;
    margin-bottom: $px;
  }
}
