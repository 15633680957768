div.ag-text-break {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 32px 0;

  &::before {
    content: "";
    display: block;
    margin-right: 8px;
    flex-grow: 1;
    height: 1px;
    background-color: var(--ag-greys-light);
  }

  &::after {
    content: "";
    display: block;
    margin-left: 8px;
    flex-grow: 1;
    height: 1px;
    background-color: var(--ag-greys-light);
  }

  & .ag-text-break-text {
    position: relative;
    z-index: 1;
    padding: 0 0.5rem;
    text-align: center;
    color: var(--ag-text-grey);
  }
}
