ion-header.ag-header {
  box-shadow: var(--ag-small-drop-shadow);
}

ion-button.ag-title-icon {
  color: var(--ag-greys-darkest);
}

ion-title.ag-title-container {
  padding: 0px;

  & .ag-title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
  }
}
