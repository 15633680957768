ion-note.ag-note {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0.5em;
  color: var(--ag-greys-black);
  font-size: 16px;
  background-color: var(--ag-status-info-background);
  position: relative;

  & ion-icon {
    color: var(--ag-status-info);
    margin-bottom: 2px;
    margin-right: 0.5em;
    font-size: 20px;
  }
}

@keyframes shake {
  0% {
    left: 0rem;
  }
  25% {
    left: 0.5rem;
  }
  75% {
    left: -0.5rem;
  }
  100% {
    left: 0rem;
  }
}

ion-note.ag-note-error {
  animation: shake 0.2s ease-in-out 0s 2;
  background-color: var(--ag-status-danger-background);

  & ion-icon {
    color: var(--ag-status-danger);
  }
}

ion-note.ag-note-success {
  background-color: var(--ag-status-success-background);

  & ion-icon {
    color: var(--ag-status-success);
  }
}
