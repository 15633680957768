.emergency-grid {
  padding-top: 0;
  padding-bottom: 0;

  padding-inline-start: 0;
  padding-inline-end: 0;

  @media (min-width: 768px) {
    flex-basis: calc(50% - 8px) !important;
  }

  .emergency-container {
    cursor: pointer;
    height: 100%;
    padding-bottom: 32px;
    padding-left: 0;

    & ion-card.ag-card {
      border-radius: 12px;
      height: 100%;

      margin-bottom: 0;
    }

    .num-of-properties-tag {
      position: relative;
      top: 8px;
      z-index: 9999;
      height: 0;

      span {
        font-weight: 700;
      }
    }
    & .ag-text.last-updated {
      color: var(--ag-text-grey);
    }
  }
}
