ion-chip.ag-tag {
  border-radius: 4px;
  padding: 6px 12px;
  margin-left: 0;
  line-height: var(--ag-text-body-small-line-height); //16px
  font-size: var(--ag-text-body-small-size); //12px


  &.ag-tag-primary {
    color: var(--ag-primary-darkest);
    background: transparent;
    border: 1px solid var(--ag-primary-darkest);

    & ion-icon.ag-tag-icon {
      color: var(--ag-primary-darkest);
    }
  }

  &.ag-tag-secondary {
    color: var(--ag-primary-darkest);
    background: var(--ag-primary-lightest);
    border: 1px solid var(--ag-primary-lightest);

    & ion-icon.ag-tag-icon {
      color: var(--ag-primary-darkest);
    }
  }

  &.ag-tag-tertiary {
    color: var(--ag-text-light);
    background: var(--ag-primary-darkest);
    border: 1px solid var(--ag-primary-darkest);

    & ion-icon.ag-tag-icon {
      color: var(--ag-text-light);
    }
  }

  &.ag-tag-info {
    color: var(--ag-text-light);
    background: var(--ag-status-info);
    border: 1px solid var(--ag-status-info);

    & ion-icon.ag-tag-icon {
      color: var(--ag-text-light);
    }
  }

  &.ag-tag-warning {
    color: var(--ag-text-light);
    background: var(--ag-status-warning);
    border: 1px solid var(--ag-status-warning);

    & ion-icon.ag-tag-icon {
      color: var(--ag-text-light);
    }
  }

  &.ag-tag-danger {
    color: var(--ag-text-light);
    background: var(--ag-status-danger);
    border: 1px solid var(--ag-status-danger);

    & ion-icon.ag-tag-icon {
      color: var(--ag-text-light);
    }
  }

  &.ag-tag-success {
    color: var(--ag-text-light);
    background: var(--ag-status-success);
    border: 1px solid var(--ag-status-success);

    & ion-icon.ag-tag-icon {
      color: var(--ag-text-light);
    }
  }

  &.ag-tag-light {
    color: var(--ag-greys-darkest);
    background: none;
    border: 1px solid var(--ag-greys-darkest);

    & ion-icon.ag-tag-icon {
      color: var(--ag-greys-darkest);
    }
  }
}
