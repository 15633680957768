.ag-bottom-sheet {
  padding: 16px 24px 0px 24px;
  background: var(--ag-primary-surface);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 50%;
  max-height: 85%;
  overflow-x: scroll;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  z-index: 999;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  &.is-open {
    transform: translateY(0%);
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3);
  }

  & .ag-bottom-sheet-children {
    margin-bottom: 3em;
  }

  & div.cancel-btn-wrapper {
    position: sticky;
    background: var(--ag-primary-surface);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    & ion-text {
      font-weight: 700;
    }
  }
}

.bottom-sheet-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
