ion-item.ag-block {
  --padding-start: 0;

  &.ag-block-primary {
    --background: var(--ag-primary-surface);
  }

  &.ag-block-secondary {
    --background: var(--ag-greys-white);
  }

  &.ag-block-transparent {
    --background: transparent;
  }

  &.ag-block-full {
    --inner-padding-end: 0;
    > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
