ion-fab.ag-icon-button {
  & ion-fab-button {
    --background: var(--ag-primary-darkest);
    --color: var(--ag-greys-white);

    &.ag-icon-button-secondary {
      --background: var(--ag-greys-darkest);
    }

    &.ag-icon-button-tertiary {
      --background: var(--ag-greys-white);
    }

    &::part(native) {
      --border-radius: 0.5em;
    }
  }
}
