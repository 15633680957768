.ag-by-address-wrapper {
  height: calc(95dvh - 56px - 76px);
  min-height: 400px;
  display: flex;
  flex-direction: column;

  /* ionic breakspoints
  xs: 0
  sm: 576px
  md: 768ox
  lg: 992px
  xl: 1200px */
  @media screen and (min-width: 992px) {
    height: calc(95vh - 56px - 76px);
  }
}
