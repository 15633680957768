.ag-text {
  &.ag-text-display-large {
    font-size: var(--ag-text-display-large-size);
    font-weight: var(--ag-text-display-large-weight);
    line-height: var(--ag-text-display-large-line-height);
  }

  &.ag-text-display-medium {
    font-size: var(--ag-text-display-medium-size);
    font-weight: var(--ag-text-display-medium-weight);
    line-height: var(--ag-text-display-medium-line-height);
  }

  &.ag-text-display-small {
    font-size: var(--ag-text-display-small-size);
    font-weight: var(--ag-text-display-small-weight);
    line-height: var(--ag-text-display-small-line-height);
  }

  &.ag-text-heading-large {
    font-size: var(--ag-text-heading-large-size);
    font-weight: var(--ag-text-heading-large-weight);
    line-height: var(--ag-text-heading-large-line-height);
  }

  &.ag-text-heading-medium {
    font-size: var(--ag-text-heading-medium-size);
    font-weight: var(--ag-text-heading-medium-weight);
    line-height: var(--ag-text-heading-medium-line-height);
  }

  &.ag-text-heading-small {
    font-size: var(--ag-text-heading-small-size);
    font-weight: var(--ag-text-heading-small-weight);
    line-height: var(--ag-text-heading-small-line-height);
  }

  &.ag-text-body-large {
    font-size: var(--ag-text-body-large-size);
    font-weight: var(--ag-text-body-large-weight);
    line-height: var(--ag-text-body-large-line-height);
  }

  &.ag-text-body-medium {
    font-size: var(--ag-text-body-medium-size);
    font-weight: var(--ag-text-body-medium-weight);
    line-height: var(--ag-text-body-medium-line-height);
  }

  &.ag-text-body-small {
    font-size: var(--ag-text-body-small-size);
    font-weight: var(--ag-text-body-small-weight);
    line-height: var(--ag-text-body-small-line-height);
  }

  &.ag-text-primary {
    &.ag-text-dark {
      color: var(--ag-text-dark);
    }

    &.ag-text-darkGrey {
      color: var(--ag-text-darkGrey);
    }

    &.ag-text-grey {
      color: var(--ag-text-grey);
    }

    &.ag-text-light {
      color: var(--ag-text-light);
    }
  }

  &.ag-text-secondary {
    color: var(--ag-text-grey);
  }

  &.ag-text-tertiary {
    color: var(--ag-primary-darkest);
  }

  &.ag-text-info {
    color: var(--ag-status-info);
  }

  &.ag-text-success {
    color: var(--ag-status-success);
  }

  &.ag-text-warning {
    color: var(--ag-status-warning);
  }

  &.ag-text-danger {
    color: var(--ag-status-danger);
  }

  &.ag-text-bold {
    font-weight: 600;
  }

  &.list-type-exclamation {
    ul {
      padding-left: 0;
      li {
        margin: 0;
        padding: 12px 0 12px 32px;
        list-style: none;
        background-image: url("../../../public/assets/error.svg");
        background-repeat: no-repeat;
        background-position: 0px 12px;
        background-size: 24px;
      }
    }
  }
}
