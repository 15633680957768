ion-card.ag-alert {
  color: var(--ag-text-dark);
  border-left: var(--ag-status-info) solid 6px;
  background-color: var(--ag-status-info-background);
  box-shadow: none;
  margin: 0;

  & ion-col.ag-alert-icon-col {
    flex-grow: 0;
    padding: 0.5em;
  }

  & ion-col.ag-alert-content-col {
    flex-grow: 1;
  }

  & ion-icon {
    padding: 0.8px;
    border-radius: 50%;
    background: var(--ag-status-info);
    color: var(--ag-greys-white);
  }

  &.ag-alert-big {
    & ion-icon {
      width: 25px;
      height: 25px;
    }
  }

  &.ag-alert-small {
    border-left: none;

    & ion-icon {
      width: 20px;
      height: 20px;
    }
  }

  & ion-card-title {
    font-weight: 600;
  }

  & ion-card-content {
    font-size: 1.2em;
    padding-left: 0;
    padding-top: 0.5em;
    padding-bottom: 0;
  }

  &::part(native) {
    box-shadow: inset;
  }

  &.ag-alert-success {
    color: var(--ag-greys-darkest);
    border-left-color: var(--ag-status-success);
    background-color: var(--ag-status-success-background);

    & ion-icon {
      background: var(--ag-status-success);
      color: var(--ag-greys-white);
    }
  }

  &.ag-alert-warning {
    color: var(--ag-greys-darkest);
    border-left-color: var(--ag-status-warning);
    background-color: var(--ag-primary-lightest);

    & ion-icon {
      background: var(--ag-status-warning);
      color: var(--ag-greys-white);
    }
  }

  &.ag-alert-danger {
    color: var(--ag-greys-darkest);
    border-left-color: var(--ag-zone-eradication-dark);
    background-color: var(--ag-status-danger-background);

    & ion-icon {
      background: var(--ag-status-danger);
      color: var(--ag-greys-white);
    }
  }
}
