ion-card.ag-zone-selector {
  border: 1px var(--ag-greys-light) solid;
  margin: 0px;
  box-shadow: none;

  & ion-button {
    text-transform: none;
    margin: 0;
    --box-shadow: none;
    --background: var(--ag-greys-white);
    --background-hover: var(--ag-primary-dark);
    --ripple-color: var(--ag-primary-light);

    & .zone-name {
      white-space: normal;
      margin-left: 12px;
    }

    &.selected {
      --background: var(--ag-primary-lightest);

      & .zone-name {
        font-weight: 600;
      }
    }
  }

  & .ag-coloured-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: -5px;
  }

  & .zone-color-0 {
    background-color: rgba(209, 0, 39, 0.4);
  }

  & .zone-color-1 {
    background: rgba(233, 96, 0, 0.4)
  }

  & .zone-color-2 {
    background: rgba(224, 189, 0, 0.4);
  }

  & ion-icon {
    color: var(--ag-greys-darkest);
  }
}