.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;

  &.overlay {
    background-color: var(--ag-primary-surface);
    opacity: 0.8;
  }

  .loading-content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    ion-spinner {
      width: 50px;
      height: 50px;
      position: relative;
      margin-left: -14px;

      background-color: var(--ag-primary-surface);
      border-radius: 100%;
      border-width: 0;
    }
  }
}
