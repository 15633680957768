ion-toggle.ag-toggle {
  padding: inherit;
  --background: var(--ag-greys-light);
  --background-checked: var(--ag-primary-darkest);
  --handle-background: var(--ag-greys-darker);
  --handle-background-checked: var(--ag-status-success);
  --track-background-checked: var(--ag-greys-light);

  --handle-spacing: -0.5em;
  transform: scale(1.25);

  &.ag-toggle-secondary {
    --background: var(--ag-primary-dark);
    --background-checked: var(--ag-primary-dark);

    --handle-background: var(--ag-greys-darker);
    --handle-background-checked: var(--ag-greys-darker);
  }
}
