ion-radio.ag-radio {
  --color-checked: var(--ag-primary-darkest);
  height: 24px;
  width: 24px;

  &::part(container) {
    border: 1.5px solid var(--ag-text-darkGrey);
  }

  &.radio-checked::part(container) {
    border: 1.5px solid var(--ag-text-darkGrey);
  }

  &::part(mark) {
    height: 16px;
    width: 16px;
  }

  &.ag-radio-secondary {
    --color-checked: var(--ag-greys-darkest);
  }
}
