.map-card-wrapper {
  border-radius: 6px;
  margin: 0;
  box-shadow: none;
  border: 1px solid #DADADA;

  & .map-expand-icon-wrapper {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 1000;
    overflow: visible;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--ag-greys-white);
    justify-content: center;
    align-items: center;

    .map-expand-icon {
      font-size: 22px;
      color: var(--ag-greys-darkest);
    }
  }

  & .map-wrapper {
    height: 178px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }
}