ion-item.ag-input {
  --padding-start: 0;
  --inner-padding-end: 0;

  &.ag-input-full-width {
    width: 100%;
  }

  & ion-grid.ag-input-grid {
    padding: 0;
  }

  & ion-grid.ag-input-grid .ag-input-inline-cta {
    flex-grow: 0;
  }

  & ion-note.ag-input-subtitle {
    padding-top: 0.25em;
  }

  & ion-input.ag-input-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    --padding-start: 1em;
    --padding-end: 1em;
    --padding-top: 0.75em;
    --padding-bottom: 0.75em;
    font-size: 16px;
    border: 1px solid var(--ag-greys-darkest);
    border-radius: 4px;
    --border-radius: 4px;
    width: 100%;

    > label {
      --padding-start: 1em;
      padding: var(--padding-top) var(--padding-end) var(--padding-bottom)
        var(--padding-start);
    }

    &.has-focus {
      border-color: var(--ag-text-highlight);
      box-shadow:
        -1px 0 0 var(--ag-text-highlight),
        1px 0 0 var(--ag-text-highlight),
        0 1px 0 var(--ag-text-highlight),
        0 -1px 0 var(--ag-text-highlight);
    }

    &.has-error {
      border-color: var(--ag-status-danger);
      box-shadow:
        -1px 0 0 var(--ag-status-danger),
        1px 0 0 var(--ag-status-danger),
        0 1px 0 var(--ag-status-danger),
        0 -1px 0 var(--ag-status-danger);
    }

    & ion-spinner.ag-input-loading {
      margin-right: 0.5em;
    }

    & ion-icon.ag-input-icon {
      margin-right: 0.5em;
      margin-left: 0.5em;
      font-size: 20px;

      &.ag-input-error {
        color: var(--ag-status-danger);
      }

      &.ag-input-success {
        color: var(--ag-status-success);
      }
    }
  }

  & ion-note.ag-input-note {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
    padding: 0.5em;
    color: var(--ag-greys-black);
    font-size: 16px;

    & ion-icon {
      margin-bottom: 2px;
      margin-right: 0.5em;
      font-size: 20px;
    }
  }

  & ion-note.ag-input-error {
    background-color: var(--ag-status-danger-background);

    & ion-icon {
      color: var(--ag-status-danger);
    }
  }

  & ion-note.ag-input-success {
    background-color: var(--ag-status-success-background);

    & ion-icon {
      color: var(--ag-status-success);
    }
  }
}
