ion-card.ag-card.card-property-view {
  padding: 8px;
  margin-bottom: 32px;

  .ag-map-wrapper {
    > div {
      border-radius: var(--ag-map-border-radius);
      border: solid 1px var(--ag-greys-light);
      height: 200px;
      width: 100%;
      flex-grow: 1;
    }
  }
}
