.icon {
  stroke: var(--ion-color-primary);
  fill: var(--ion-color-primary);
  width: 50px;
  height: 50px;
}

.notification-icon-wrapper {
  position: relative;

  .notifications-unread {
    display: none;
    position: absolute;
    top: 0%;
    right: -10%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--ag-primary-darkest);
  }
}
