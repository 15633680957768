.snsw-container {
  //centre the button
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //set the width of the container
  width: 100%;
}

button.snsw-button {
  color: #ffffff;
  font-family: "Gotham", Arial, sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 1;
  background: #d7153a;
  height: 48px;

  width: 95%;

  border-radius: 8px !important;

  padding: 16px 24px 16px 24px;

  &:hover,
  &:focus {
    background: #bf0c2a;
  }
  &:active {
    background: #d7153a;
    opacity: 0.8;
  }
}

p.ag-snsw-blurb {
  font-family: "Gotham", Arial, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 1.5;
  color: #242934;
}
