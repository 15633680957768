ion-card.ag-nav-card {
  border-radius: 12px;
  border: 1px solid var(--ag-greys-light);
  box-shadow: none;
  margin: 0;

  & ion-card-content {
    background: var(--ag-primary-light);
    height: 105px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;

    & ion-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0px;
    }
  }
}