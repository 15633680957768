:root {
  --ag-text-display-large-size: 36px;
  --ag-text-display-large-line-height: 45px;
  --ag-text-display-large-weight: 600;

  --ag-text-display-medium-size: 28px;
  --ag-text-display-medium-line-height: 36px;
  --ag-text-display-medium-weight: 600;

  --ag-text-display-small-size: 24px;
  --ag-text-display-small-line-height: 32px;
  --ag-text-display-small-weight: 400;

  --ag-text-heading-large-size: 22px;
  --ag-text-heading-large-line-height: 28px;
  --ag-text-heading-large-weight: 600;

  --ag-text-heading-medium-size: 18px;
  --ag-text-heading-medium-line-height: 24px;
  --ag-text-heading-medium-weight: 600;

  --ag-text-heading-small-size: 16px;
  --ag-text-heading-small-line-height: 24px;
  --ag-text-heading-small-weight: 400;

  --ag-text-body-large-size: 16px;
  --ag-text-body-large-line-height: 24px;
  --ag-text-body-large-weight: 400;

  --ag-text-body-medium-size: 14px;
  --ag-text-body-medium-line-height: 20px;
  --ag-text-body-medium-weight: 400;

  --ag-text-body-small-size: 12px;
  --ag-text-body-small-line-height: 16px;
  --ag-text-body-small-weight: 400;
}
