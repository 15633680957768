.ag-download-app-alert {
  position: relative;
  color: var(--nsw-status-warning-orange);
  background-color: var(--nsw-status-warning-orange-bg);
  border-left: 5px solid var(--nsw-status-warning-orange);
  padding-right: 32px !important;
  line-height: 1.5;
  font-size: 0.75em;
  @media (min-width: 992px) {
    font-size: 1em;
  }

  &--floating {
    display: inline-block;
    position: absolute;
  }

  a,
  button {
    color: var(--nsw-status-warning-orange);
    background-color: transparent;
    text-decoration: underline;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
