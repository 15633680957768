hr.ag-divider {
  border-top: 1.5px var(--ag-greys-darker) solid;

  &.ag-divider-secondary {
    border-top: 1.5px var(--ag-greys-light) solid;
  }

  &.ag-divider-tertiary {
    border-top: 1.5px var(--ag-greys-lighter) solid;
  }
}