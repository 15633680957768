ion-button.ag-button {
  font-size: 16px;
  text-transform: none;
  height: 3em;
  letter-spacing: normal;

  & a {
    color: inherit;
    text-decoration: none;
  }

  &.ag-button-small {
    font-size: 14px;
  }

  --background: var(--ag-primary-darkest);
  --color: var(--ag-greys-white);

  &.ag-button-secondary {
    --background: var(--ag-greys-darkest);
  }

  &.ag-button-tertiary {
    --background: var(--ag-greys-lighter);
    --color: var(--ag-greys-darkest);

    & ion-text {
      color: var(--ag-greys-darkest);
    }
  }

  &.ag-button-light {
    --background: var(--ag-greys-white);
    --color: var(--ag-greys-darkest);

    & ion-text {
      color: var(--ag-greys-darkest);
    }
  }
  &.ag-button-success {
    --background: var(--ag-status-success-background);
    --color: var(--ag-status-success);

    & ion-text {
      color: var(--ag-greys-darkest);
    }
  }

  &.ag-button-success-alt {
    --background: var(--ag-greys-white);
    --color: var(--ag-status-success);

    & ion-text {
      color: var(--ag-greys-darkest);
    }
  }

  &.ag-button-link {
    --background: transparent;
    --color: var(--ag-greys-darkest);
    text-decoration: underline;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    font-size: inherit;
    height: auto;
    margin: 0;
    min-height: auto;
    margin-inline: inherit;
    vertical-align: middle;

    & ion-text {
      color: var(--ag-greys-darkest);
    }
  }

  &.ag-button-snsw {
    --background: #d7153a;
    --color: #ffffff;

    & ion-text {
      color: #ffffff;
    }

    &:hover,
    &:focus {
      --background: #bf0c2a;
    }
  }

  &.ag-button-info {
    --background: #2e5299;
  }

  &.ag-button-outline {
    --background: transparent;
    color: var(--ag-greys-black);

    & ion-text {
      color: var(--ag-greys-black);
      text-decoration: solid underline;
      font-weight: 700;
    }
  }

  &.ag-button-clear {
    --background: transparent;
    color: var(--ag-greys-black);

    & ion-text {
      color: var(--ag-greys-black);
    }
  }

  &.ag-button-loading {
    opacity: 1;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 12px);
      right: 12px;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;

      border-top: 4px solid var(--ag-primary-lightest);
      border-right: 4px solid var(--ag-primary-lightest);
      border-bottom: 4px solid var(--ag-primary-lightest);
      border-left: 4px solid var(--ag-primary-darker);
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: spinner 1.1s infinite linear;
      animation: spinner 1.1s infinite linear;
    }
  }

  --border-radius: 24px;
  --border-width: 0;

  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 24px;
  --padding-end: 24px;
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
