div.ag-address-search {
  width: 100%;

  & div.leaflet-control {
    width: 100%;
  }
}

div.ag-address-guidance {
  width: 100%;
  background-color: var(--ag-status-info-background);
  padding: 8px 16px;
}