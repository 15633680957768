.leaflet-container {
  .leaflet-control-container {
    .leaflet-control-zoomslider {
      border: none;
      background-color: #fff;
      border-radius: 24px;
      a {
        &.leaflet-bar-part-top,
        &.leaflet-bar-part-bottom {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 32px;
          border: none;
        }

        &.leaflet-bar-part-top {
          margin-bottom: 4px;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
        }

        &.leaflet-bar-part-bottom {
          margin-top: 4px;
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }

      .leaflet-control-zoomslider-wrap {
        border: 0;
        padding: 7px 0;
        .leaflet-control-zoomslider-body {
          border-width: 0;
          background-color: var(--ag-greys-light);

          .leaflet-control-zoomslider-knob {
            border-radius: 100%;
            width: 16px;
            height: 16px;
            background-color: #fff;
            border-color: #000;
            border-width: 2px;
          }
        }
      }
    }

    .leaflet-control-zoom {
      transform: translate(-10px, -12px);
      border: none;
      a {
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 32px;
          border: none;
        }

        &.leaflet-control-zoom-in {
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
        }

        &.leaflet-control-zoom-out {
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }
    }
  }
}
