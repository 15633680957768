.leaflet-popup.affected-property-popup {
  button {
    background: transparent;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 24px;
    text-align: center;
    box-shadow: 0px 2px 6px 0px #00000026;

    & .leaflet-popup-content {
      padding: 8px 16px;
      margin: 0;
    }
  }
}
