ion-list.ag-list {
  &.ag-list-primary {
    background-color: var(--ag-primary-surface);
  }

  &.ag-list-secondary {
    background-color: var(--ag-greys-white);
  }

  &.ag-list-transparent {
    background-color: transparent;
  }

  & ion-item.ag-list-item {
    --padding-start: 1em;
  }
}
