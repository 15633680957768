ion-spinner {
  position: fixed;
  top: 4px;
  left: 8px;
  right: 0px;
  z-index: 1000;
}

.legend-button-wrapper {
  position: fixed;
  top: 4px;
  left: 8px;
  right: 0px;
  z-index: 1000;
}

.legend-cards {
  & .colored-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &.color-1 {
      background: rgba(209, 0, 39, 0.4);
    }

    &.color-2 {
      background: rgba(233, 96, 0, 0.4);
    }

    &.color-3 {
      background: rgba(224, 189, 0, 0.4);
    }
  }
}

.info-card-wrapper {
  position: fixed;
  bottom: 10px;
  left: 16px;
  right: 16px;
  z-index: 1000;
}

.map-wrapper {
  height: calc(100dvh - 56px);
  display: flex;
  flex-direction: column;
}
