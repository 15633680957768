ion-card.ag-notification-card {
  margin: 0;
  margin-bottom: 1em;
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid;
  border-left-width: 6px;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }

  & ion-card-header.ag-notification-card-header {
    padding: 0px;
    margin-bottom: 4px;

    & .ag-notification-subtitle span {
      color: var(--ag-text-grey);
    }
  }

  & ion-card-content.ag-notification-card-content {
    padding: 0px;

    & .ag-notification-content {
      max-height: 48px;
      overflow: hidden;
      position: relative;

      & span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.unread-notification {
    border-color: var(--ag-primary-darkest);
    --background: var(--ag-primary-lightest);

    & .ag-notification-content span {
      font-weight: 700;
    }
  }

  &.read-notification {
    --background: var(--ag-greys-white);
    border-color: var(--ag-greys-darkest);
  }
}