ion-fab.ag-fab {
  & ion-fab-button {
    --background: var(--ag-primary-darkest);
    --color: var(--ag-greys-white);

    --box-shadow: var(--ag-small-drop-shadow);

    &.ag-fab-small {
      height: 48px;
      width: 48px;
    }

    &.ag-fab-secondary {
      --background: var(--ag-greys-darkest);
    }

    &.ag-fab-tertiary {
      --background: var(--ag-greys-white);
    }

    &.ag-fab-label {
      margin-bottom: 8px;
    }
  }

  & span {
    color: var(--ag-greys-darkest);
  }
}
