.ag-map-wrapper {
  > div {
    border-radius: var(--ag-map-border-radius);
    border: solid 1px var(--ag-greys-light);
    height: 200px;
    width: 100%;
    flex-grow: 1;
  }
}

.editable-data-wrapper {
  --ion-grid-column-padding: 0;
  padding: 0;

  .editable-data-cta-wrapper {
    flex-grow: 0;
  }
}
