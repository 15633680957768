ion-item.ag-checkbox,
div.ag-checkbox {
  & div.ag-checkbox-border {
    margin-right: 1em;

    & ion-checkbox.ag-checkbox-checkbox {
      --checkmark-color: var(--ion-color-primary-contrast);
      --size: 1.5em;

      &::part(container) {
        --border-color: var(--ag-text-darkGrey);
        --border-color-checked: var(--ag-text-darkGrey);
        --background: var(--ag-greys-white);
        padding: 0.25em;
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
      }

      &.ag-checkbox-secondary {
        --background-checked: var(--ag-greys-darkest);
      }

      &:focus::part(container) {
        --border-color: var(--ag-text-highlight);
        box-shadow: -1px 0 0 var(--ag-text-highlight),
          1px 0 0 var(--ag-text-highlight), 0 1px 0 var(--ag-text-highlight),
          0 -1px 0 var(--ag-text-highlight);
      }
    }

    & label {
      font-size: 1em;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

div.ag-checkbox {
  display: flex;
  align-items: top;
  justify-content: flex-start;
  margin-bottom: 24px;
}
